import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: string): string {
    const date = new Date(value);
    const day = date.getUTCDate();
    const month = date.toLocaleString('es-ES', { month: 'long' }); // Obtén el nombre del mes en español
    const year = date.getUTCFullYear();

    return `${day} ${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`;
  }

}
